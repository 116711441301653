<template>
  <div class="wrapper">
    <img class="order-img" src="/img/order_top.png" alt="">
    <!--收货地址-->
    <div class="user-address-box flex ai-c jc-sb ai-c"
      @click="$router.push({ path: '/my/addressList', query: { id: orderInfo.userAddressId, select: 1 } })">
      <template v-if="orderInfo.userAddressId">
        <div class="address-detail">
          <div class="user-basic-info flex ai-c jc-sb">
            <div class="user-name">{{ orderInfo.name }}</div>
            <div class="user-phone">{{ plusXing(orderInfo.phone, 4, 3) }}</div>
          </div>
          <div class="user-address flex-box">
            <div class="address lines">
              {{ orderInfo.address }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="no-address">添加收货地址</div>
      </template>
      <van-icon name="arrow" />
    </div>
    <!--订单列表-->
    <div class="goods-item">
      <div class="goods-shop">
        <img src="/img/icon-shop.png" alt="">
        i团团云供应链
      </div>
      <div class="list">
        <div class="goods flex" v-for="(im, index) in orderInfo.item" :key="index">
          <img :src="im.pic" alt="">
          <div class="" style="width: calc(100% - 1.84rem)">
            <div class="flex ai-bl jc-sb">
              <div class="name line">{{ im.name }}</div>
              <div class="price">
                ¥{{ im.price.toFixed(2) }}
                <!-- <font>10</font> -->
              </div>
            </div>
            <div class="flex ai-c jc-sb" style="margin-top:.1rem">
              <div class="classification line">{{ im.specifications }}</div>
              <div class="nb">x{{ im.quantity }}</div>
            </div>
            <!-- <div class="fw">7天无理由退换</div> -->
          </div>
        </div>
      </div>

      <div class="picker-box flex ai-c jc-sb" style="margin-top: .2rem">
        <div class="picker-name">温馨提醒</div>
        <div class="flex ai-c">
          <div class="txt">下单前请仔细核对收货地址哦</div>
        </div>
      </div>
      <div class="picker-box flex ai-c jc-sb">
        <div class="picker-name">配送方式</div>
        <div class="flex ai-c">
          <div class="txt">快递免邮</div>
          <!-- <van-icon name="arrow" color="rgba(170, 170, 170, 1)" /> -->
        </div>
      </div>
      <div class="picker-box flex ai-c jc-sb" @click="$router.push({ path: '/home/orderConfirm/coupons' })">
        <div class="picker-name flex ai-c"><img src="/img/icon-yhq.png" alt=""> 优惠券</div>
        <div class="flex ai-c" v-if="couponList.length == 0">
          <div class="txt">暂无优惠券</div>
          <van-icon name="arrow" color="rgba(170, 170, 170, 1)" />
        </div>
        <div class="flex ai-c" v-if="couponList.length != 0 && preferentialAmount != 0">
          <div class="txt" style="color:#F35E3A">-￥{{ preferentialAmount }}</div>
          <van-icon name="arrow" color="rgba(170, 170, 170, 1)" />
        </div>
        <div class="flex ai-c bgc" v-if="couponList.length != 0 && preferentialAmount == 0">
          <div class="txt" style="color:#fff">{{ couponList.length }}张可用</div>
          <van-icon name="arrow" color="#fff" />
        </div>
      </div>
      <div class="picker-box flex ai-c jc-sb" @click="$router.push('/my/invoice')">
        <div class="picker-name">开具发票</div>
        <div class="flex ai-c">
          <div class="txt">本次不开具发票</div>
          <van-icon name="arrow" color="rgba(170, 170, 170, 1)" />
        </div>
      </div>
      <div class="picker-box flex ai-c jc-sb">
        <div class="picker-name">订单备注</div>
        <div class="flex ai-c">
          <input v-model="orderInfo.note" placeholder="选填,请先和商家协商一致" class="txt txtinput" />
        </div>
      </div>

      <div class="subtotal flex ai-c jc-fd">
        <!-- <div class="subtotal-zl">共2件商品</div> -->
        <div class="subtotal-lb">小计：</div>
        <div class="subtotal-price">¥{{ totalAmount }}</div>
      </div>
    </div>
    <div class="kong"></div>
    <div class="btn-box flex ai-c jc-sb">
      <div class="subtotal flex ai-c jc-fd">
        <div class="subtotal-zl">共{{ totalQuantity }}件</div>
        <div class="subtotal-lb">实付金额：</div>
        <div class="subtotal-price">¥{{ totalAmount }}</div>
      </div>
      <div class="btn" @click="orderCreate()">提交订单</div>
    </div>
  </div>
</template>

<script>
import { addressDefault, orderCreate, unifiedOrder, couponUsable, orderPay } from '../../../api'
export default {
  components: {},
  props: {},
  data() {
    return {
      orderInfo: {},
      totalQuantity: 0,
      totalAmount: 0,
      couponList: [],
      coupon: {},
      preferentialAmount: 0
    };
  },
  watch: {},
  computed: {},
  methods: {
    //可用优惠券列表
    couponUsable() {
      var goodsList = this.orderInfo.item
      var arr = []
      for (var item of goodsList) {
        arr.push({
          cartId: item.cartId ? item.cartId : '',
          quantity: item.quantity,
          skuId: item.skuId
        })
      }
      localStorage.removeItem('couponList')
      couponUsable(arr).then(res => {
        var data = res.data.data
        localStorage.setItem('couponList', JSON.stringify(data))
        this.couponList = data
        var totalAmount = 0
        var amount = 0
        for (var im of this.orderInfo.item) {
          this.totalQuantity += im.quantity
          totalAmount = this.NumberAdd(amount, this.NumberMul(im.quantity, im.price))
          amount = totalAmount
        }
        for (var item of data) {
          if (item.id == this.orderInfo.couponId) {
            var coupon = item.coupon
            if (coupon.discountType == 0) {
              totalAmount = this.NumberSub(totalAmount, coupon.amount) <= 0 ? 0.01 : this.NumberSub(totalAmount, coupon.amount)
              this.preferentialAmount = Number(this.NumberSub(amount, totalAmount))
            } else {
              totalAmount = Math.ceil(this.NumberMul(totalAmount, coupon.proportion) * 100) / 100
              this.preferentialAmount = Number(this.NumberSub(amount, totalAmount))
            }
          }
        }
        this.totalAmount = Number(totalAmount).toFixed(2)
      })
    },
    //获取默认地址
    addressDefault() {
      addressDefault({}).then(res => {
        var data = res.data.data
        if (!data.id) {
          return
        }
        var orderInfo = this.orderInfo

        orderInfo.userAddressId = data.id
        orderInfo.name = data.name
        orderInfo.phone = data.phone
        orderInfo.address = data.province + (data.province == data.city ? '' : data.city) + data.region + data.detailAddress
        this.orderInfo = orderInfo
        this.$forceUpdate()
        localStorage.setItem('orderInfo', JSON.stringify(orderInfo))
      })
    },
    //手机号加星
    plusXing(str, frontLen, endLen) {
      str = String(str)
      var len = str.length - frontLen - endLen;
      var xing = '';
      for (var i = 0; i < len; i++) {
        xing += '*';
      }
      return str.substr(0, frontLen) + xing + str.substr(str.length - endLen);
    },
    //创建订单
    orderCreate() {
      var orderInfo = JSON.parse(JSON.stringify(this.orderInfo))
      if (!this.orderInfo.userAddressId) {
        return this.$toast('请添加收货地址')
      }
      this.$toast.loading({
        duration: 0
      })
      delete orderInfo.address
      delete orderInfo.name
      delete orderInfo.phone
      for (var im of orderInfo.item) {
        delete im.name
        delete im.price
        delete im.pic
        delete im.specifications
      }
      // payScene支付方式：1->公众号支付; 2->APP支付;
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        orderInfo.payScene = 1
      } else if (ua.match(/xmsmk/i) == "xmsmk") {
        orderInfo.payScene = 2
      } else {
        orderInfo.payScene = 3
      }
      // orderInfo.payScene = ua.match(/MicroMessenger/i) == "micromessenger"?1:2
      orderCreate(orderInfo).then(res => {
        this.$router.replace({ path: '/my/orderListSMCS', query: { type: 1 } })
        // this.unifiedOrder(res.data.data)
      }).catch((res) => {
        this.$toast.clear()
        if (res.data.code == 1014 && this.$route.query.type == "cart") {
          this.$toast({
            message: '购物车存在下架商品，请返回购物车重新下单',
            forbidClick: true
          })
        }
        if (res.data.code == 1015 && this.$route.query.type == "cart") {
          this.$toast({
            message: '购物车存在商品库存不足，请返回购物车重新下单',
            forbidClick: true
          })
        }
        if (res.data.code == 1014 && this.$route.query.type != "cart") {
          this.$toast({
            message: '该商品已经下架，请返回重新选择商品',
            forbidClick: true
          })
        }
        if (res.data.code == 1015 && this.$route.query.type != "cart") {
          this.$toast({
            message: '该商品库存不足，请返回重新选择商品',
            forbidClick: true
          })
        }

        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      })
    },
    //获取支付订单号
    unifiedOrder(data) {
      unifiedOrder(data).then(res => {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          console.log('在微信端');
          this.wxPay(res.data.data, data)
        } else if (ua.match(/xmsmk/i) == "xmsmk") {
          this.pay(res.data.data, data)
        } else {
          this.ixAppPay(res.data.data, data)
        }

      }).catch(res => {
        this.$toast.clear()
      })
    },
    //i厦门app支付
    ixAppPay(orderNo, orderSn) {
      console.log(orderNo);
      console.log(localStorage.getItem("payThirdSystemId"));
      ixm.openPay((res) => {
        // 成功回调
        console.log(res);
        if (res.result) {
          this.$toast.success({
            message: '支付成功',
            duration: 1500,
            forbidClick: true,
          })
          setTimeout(() => {
            this.$router.replace({ path: '/my/orderListSMCS' })
          }, 1500)
        } else {
          this.$router.replace({ path: '/my/orderListSMCS', query: { type: 0 } })
        }
      }, {
        orderId: orderNo,  // 订单标识
        thirdSystemId: localStorage.getItem("payThirdSystemId"), // 第三方应用标识
      })
    },
    //微信支付
    wxPay(orderNo, orderSn) {
      // location.href='http://218.5.69.218:9088/smk/smk-unipay-transfer/#/?orderNo='+orderNo
      location.href = 'https://smk.ixiamen.org.cn/smk/smk-unipay-transfer/#/?orderNo=' + orderNo
    },
    //厦门市民卡app支付
    pay(orderNo, orderSn) {
      var that = this
      var ua = navigator.userAgent
      var preloadJs = function () {
        // Promise语法是ES6⽀持的，其他不⽀持的请⾃⾏转成ES5
        return new Promise((resolve, rejct) => {
          if (ua.toLowerCase().indexOf("xmsmk") !== -1) {
            if (window.UmsApi === undefined) {
              document.addEventListener('OnUmsApiReady',
                function () {
                  resolve(true)
                }, false)
            } else {
              resolve(true)
            }
          } else {
            // ⾮市⺠卡接⼊
            reject('⾮市⺠卡接⼊')
          }
        })
      }
      function unifyPay(orderNumber) {
        UmsApi.page.processUnifyPay({ orderNo: orderNumber }, function (data) {
          // data中respCode为：0000 表示成功，其他情况失败

          if (data.errCode == '0000') {
            console.log("成功返回数据:" + JSON.stringify(data))

            that.orderPay(data.errCode, orderSn)
          } else {
            console.log("失败返回数据:" + JSON.stringify(data))
            that.orderPay(data.errCode, orderSn)
          }
        }, function (data) {
          //失败
          that.$router.replace({ path: '/my/orderListSMCS', query: { type: 0 } })
          console.log("失败返回数据:" + JSON.stringify(data))
        });
      }
      preloadJs().then(res => {
        // 调⽤市⺠卡相关⽅法
        that.$toast.clear()
        unifyPay(orderNo)
      })
    },
    orderPay(code, orderSn) {
      orderPay(orderSn).finally(() => {
        if (code == '0000') {
          this.$toast.success({
            message: '支付成功',
            duration: 1500,
            forbidClick: true,
          })
          setTimeout(() => {
            this.$router.replace({ path: '/my/orderListSMCS' })
          }, 1500)
        } else {
          this.$router.replace({ path: '/my/orderListSMCS', query: { type: 0 } })
        }
      })
    }
  },
  created() {
    var orderInfo = JSON.parse(localStorage.getItem('orderInfo'))

    this.orderInfo = orderInfo
    if (!orderInfo.userAddressId) {
      this.addressDefault()
    }
    this.couponUsable()
  },
  mounted() { },
};
</script>
<style scoped>
.wrapper {}

.order-img {
  width: 100%;
  display: block;
}

.user-address-box {
  width: 100%;
  padding: 0.2rem 0.1rem 0.2rem 0.3rem;
  background: #fff;
  margin-bottom: 0.25rem;
  box-sizing: border-box;
}

.no-address {
  width: 100%;
  margin: 0.3rem;
  margin-left: 0;
  position: relative;
  font-size: 0.32rem;
  font-weight: bold;
}

.address-detail {
  width: 6.5rem;
}

.user-basic-info {
  width: 100%;
  margin-bottom: 0.15rem;
}

.user-name,
.icon-box {
  font-size: 0.32rem;
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-phone,
.address {
  font-size: 0.32rem;
  width: 65%;
}

.address {
  width: 90%;
}

.default {
  padding: 0 0.1rem;
  color: #d54848;
  font-size: 0.22rem;
  border: 1px solid #e58a8a;
  border-radius: 0.08rem;
}

.address {
  font-size: 0.26rem;
}

.goods-item {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  margin-top: 0.2rem;
  padding-bottom: 0.7rem;
}

.goods-item .goods-shop {
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: bold;
  color: #61626a;
  margin-left: 0.3rem;
  margin-top: 0.3rem;
}

.goods-item .goods-shop img {
  width: 0.22rem;
  height: 0.2rem;
  margin-right: 0.1rem;
}

.list {
  margin-top: 0.1rem;
}

.list .goods {
  width: 100%;
  box-sizing: border-box;
  padding: 0.3rem 0.3rem;
}

.list .goods img {
  width: 1.54rem;
  height: 1.54rem;
  margin-right: 0.3rem;
  object-fit: cover;
}

.list .goods .name {
  /* width: auto; */
  /* width: 3.90rem; */
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-right: 0.16rem;
}

.list .goods .price {
  white-space: nowrap;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}

.list .goods .price font {
  font-size: 0.24rem;
}

.list .goods .classification {
  max-width: 3.5rem;
  height: 0.38rem;
  padding: 0 0.05rem;
  line-height: 0.38rem;
  box-sizing: border-box;
  background: #f4f4f4;
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
}

.list .goods .nb {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
}

.list .goods .fw {
  width: fit-content;
  height: 0.28rem;
  line-height: 0.28rem;
  padding: 0 0.05rem;
  background: #f9efed;
  font-size: 0.2rem;
  font-family: PingFang;
  font-weight: 500;
  color: #f6593f;
  margin-top: 0.4rem;
}

.picker-box {
  width: 100%;
  padding: 0.24rem 0.2rem 0.24rem 0.3rem;
  box-sizing: border-box;
}

.picker-box .picker-name {
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}

.picker-box .picker-name img {
  width: 0.26rem;
  height: 0.35rem;
  margin-right: 0.1rem;
}

.picker-box .txt {
  width: 5rem;
  text-align: right;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-right: 0.1rem;
}

.picker-box .txtinput {
  border: none;
  text-align: left;
}

.subtotal {
  margin-top: 0.5rem;
}

.subtotal-zl {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
}

.subtotal-lb {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-left: 0.15rem;
}

.subtotal-price {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 800;
  color: #f35e3a;
  margin-right: 0.38rem;
}

.subtotal-price font {
  font-size: 0.28rem;
}

.kong {
  width: 100%;
  height: 2.6rem;
}

.btn-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.3rem;
  background-color: #fff;
  padding-bottom: constant(safe-area-inset-bottom);
  /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom);
  /*兼容 IOS>11.2*/
}

.btn-box .subtotal {
  width: fit-content;
  margin-left: 0.3rem;
  margin-top: 0;
}

.btn-box .btn {
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background: #f35e3a;
  border-radius: 0.4rem;
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
  margin-right: 0.3rem;
}

.bgc .txt {
  width: auto;
}

.bgc {
  padding: 0 0.1rem;
  height: 0.4rem;
  background: linear-gradient(90deg, #f35e3a 0%, #e93a3b 100%);
  border-radius: 0.2rem;
}
</style>